/* bas = bits and spaces */
/* Place this as the wrapper to the element you want to apply the radial shadow to. The inner 
  content's background color must be set to something, otherwise you'll see the wrapper's circular
  border. 
  NOTE: Ensure to set the wrapper and image with at least the same width. */
.bas-radial-shadow {
  border-radius: 50%;
  box-shadow: 0px 10px 15px 13px rgba(135, 135, 135, 0.8)
}
.bas-square-shadow {
  box-shadow: 10px 8px 12px 6px rgba(135,135,135,0.9);
}
.bas-rounded-corners {
  border-radius: 10px;
}

.bas-brand-icon {
  margin-left: 1rem;
}
.bas-brand-icon > img {
  width: 8rem;
}